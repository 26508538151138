import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	IonCard,
	IonCardTitle,
	IonCardSubtitle,
	IonCardHeader,
	IonCardContent,
	useIonAlert,
	useIonLoading
} from '@ionic/react';


import { DataContext } from '../../Providers/DataProvider';
import { deviceType } from 'react-device-detect';


import tableone from '../../Assets/tableone-gold.png'
import tableoneBlue from '../../Assets/tableone-notable-blue.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';


import './ViewNew.scoped.scss';

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewNew = () => {
	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [referrer, setReferrer] = useState(false);
	const [partner, setPartner] = useState(false);

	const {
		handleReferral,
		handlePartner,
		handleClick
	} = useContext(DataContext)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [present, dismiss] = useIonLoading();

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	async function handleSubmit() {
		const queryParameters = new URLSearchParams(window.location.search)


		const referrer = queryParameters.get('referrer')
		console.log(localEmail, referrer.length)

		if (!validateEmail(localEmail) || referrer.length !== 36) {
			presentAlert({
				message: 'Valid email & referrer Required',
				duration: 1500,
				position: 'middle',
				cssClass: 'custom-alert',
				buttons: ['OK']
			});
			return;
		} else {
			await present('Saving referral')

			await handleReferral(
				referrer,
				localEmail
			)

			await dismiss()

			window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_self');
		}

	}

	function toArticle(url) {
		window.open(url, '_blank');
	}

	async function toUrl(url) {
		window.open(url);
	};

	async function toDownload() {
		handleClick()

		// const os = getMobileOperatingSystem()

		window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');

		// if (os === "iOS") {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// } 
		// else if (os === "Android") {
		// 	window.open('https://play.google.com/store/apps/details?id=com.fyi.hooli.app', '_blank');
		// } 
		// else {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// }
	};

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('referrer');

			if (tmpReferrer?.length == 36) {
				setReferrer(tmpReferrer);
			}

			const tmpPartner = queryParameters.get('partner');
			if (tmpPartner) {
				setPartner(tmpPartner)
			}

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	useEffect(() => {
		async function handle() {
			await handlePartner(partner, 'mobile')
		}

		if (partner) {
			handle()
		}
	},[partner])

	return (
		<IonPage fullscreen>
			{/* <IonHeader style={{ '--background': 'transparent' }}>
				<IonGrid style={{ '--background': 'transparent' }}>
					<IonRow>
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonHeader> */}


			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true}>
				<IonGrid style={{ height: '100%', padding: 0 }}>
					<IonRow style={{ height: '100vh' }} className='city'>
						<IonCol>
							<IonRow>
								<IonCol style={{ textAlign: 'left', marginTop: '2rem' }}>
									<img src={tableone} style={{ height: '3rem' }} />
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '40vh' }}>
								<IonCol>
									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '3rem',
												color: '#F6F1EF'
											}}>
												Serving <span style={{ color: "#F2C498" }}>access</span><br />to the <span style={{ color: "#F2C498" }}>best</span> restaurants.
											</span>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
												color: '#F6F1EF'
											}}>
												Exclusively in New York City.
											</span>
										</IonCol>
									</IonRow>

									<IonRow>
										<IonButton
											onClick={() => (toDownload())}
											shape="round"
											style={{
												fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
												fontWeight: 600,
												height: '5rem',
												width: '13rem',
												'--padding-left': 0,
												'--background': '#F6F1EF',
												'--background-activated': '#F6F1EF',
												'--background-hover': '#F6F1EF',
												'--color': '#000000'

											}}
										>
											Download Now
										</IonButton>
									</IonRow>
								</IonCol>
							</IonRow>

						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#F6F1EF', height: 'auto', textAlign: 'center' }}>
						<IonCol style={{ padding: 0 }}>
							<IonRow style={{ marginTop: '5vh' }}>
								<IonCol>
									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '3rem',
												color: '#000000',
												fontWeight: 600
											}}>
												<span style={{ color: "#6633FF" }}>Platinum</span> card dining<br />without the card.
											</span>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
												color: '#000000'
											}}>
												We aggregate availability across all major reservation platforms, in true real-time to ensure you never miss a table and equip you with the fastest notify system in the market.
											</span>
										</IonCol>
									</IonRow>

									<IonRow>
										<IonCol>
											<IonButton
												onClick={() => (toDownload())}
												shape="round"
												style={{
													fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
													fontWeight: 600,
													height: '5rem',
													width: '13rem',
													'--padding-left': 0,
													'--background': '#000000',
													'--background-activated': '#000000',
													'--background-hover': '#000000',
													'--color': '#FFFFFF'

												}}
											>
												Download Now
											</IonButton>
										</IonCol>
									</IonRow>
								</IonCol>
							</IonRow>

							<IonRow style={{ height: '40rem', marginTop: '3rem', marginBottom: '3rem' }} className='screenshots' />

							<IonRow>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '3rem',
										color: '#000000',
										fontWeight: 600
									}}>
										A booking experience<br />designed for <span style={{ color: "#6633FF" }}>you</span>.
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
										color: '#000000'
									}}>
										Never before seen filters and features to make finding your perfect table easier than its ever been.
									</span>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonButton
										onClick={() => (toDownload())}
										shape="round"
										style={{
											fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
											fontWeight: 600,
											height: '5rem',
											width: '13rem',
											'--padding-left': 0,
											'--background': '#000000',
											'--background-activated': '#000000',
											'--background-hover': '#000000',
											'--color': '#FFFFFF'

										}}
									>
										Download Now
									</IonButton>
								</IonCol>
							</IonRow>

							<IonRow style={{ height: '40rem', marginBottom: '4rem' }}>
								<IonCol>
									<Swiper
										initialSlide="0"
										// install Swiper modules
										modules={[Navigation]}
										// direction='horizontal'
										spaceBetween={0}
										slidesPerView={1.2}
										// scrollbar={{ draggable: true }}
										// onSwiper={(swiper) => console.log(swiper)}
										allowTouchMove={true}
										// onSlideChange={(e) => handleButtonTheme(e)}
										// onInit={() => setInit(true)}
										// ref={swiper}
										style={{
											"background": "#F6F1EF",
										}}
									>
										<SwiperSlide style={{ "background": "transparent" }} key={"0"}>
											{/* <p>heyt</p> */}
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/notifications.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left', height: '7.5rem' }}>
													<IonCardTitle>Notify</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left', height: '7.rem' }}>
													The fastest alerts available.
												</IonCardContent>
											</IonCard>
										</SwiperSlide>
										<SwiperSlide style={{ "background": "transparent" }} key={"1"}>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/tablechance.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left', height: '7.5rem' }}>
													<IonCardTitle>TableChance</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left', height: '7.rem' }}>
													Know your odds at finding a table.
												</IonCardContent>
											</IonCard>
										</SwiperSlide>
										<SwiperSlide style={{ "background": "transparent" }} key={"2"}>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/realtime.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left' }}>
													<IonCardTitle>Availability</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left' }}>
													Look for tables in real-time, no refreshing required.
												</IonCardContent>
											</IonCard>
										</SwiperSlide>
										<SwiperSlide style={{ "background": "transparent" }} key={"3"}>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/best.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left' }}>
													<IonCardTitle>Only the best</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left' }}>
													TableOne's restaurant list is entirely decided by its users.
												</IonCardContent>
											</IonCard>
										</SwiperSlide>
									</Swiper>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow
						style={{
							height: '30rem'
						}}
						className='news1'
					>
						<IonCol style={{ textAlign: 'center' }}>
							<IonRow style={{ height: '5rem' }} onClick={() => (toUrl("https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation"))} />

							<IonRow style={{ marginTop: '3rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#FFFFFF'
									}}>
										“Having an American Express Platinum Card may help...<br />
										walk-ins for bar seats can yield results.<br /><br />
										But the <span style={{ color: "#F2C498" }}>best</span> way to get these reservations is to<br /> download <span style={{ color: "#F2C498" }}>TableOne</span>.”<br/><br/>
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ height: '5rem' }} onClick={() => (toUrl("https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates"))} />
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#F6F1EF', height: 'auto', textAlign: 'center' }}>
						<IonCol style={{ padding: 0 }}>
							<IonRow style={{ marginTop: '5vh' }}>
								<IonCol>
									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '3rem',
												color: '#000000',
												fontWeight: 600
											}}>
												Elite dining is now<br /><span style={{ color: "#6633FF" }}>affordable</span>.
											</span>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
												color: '#000000'
											}}>
												No costly annual fee or minimum spends required.
											</span>
										</IonCol>
									</IonRow>

									<IonRow>
										<IonCol>
											<IonButton
												onClick={() => (toDownload())}
												shape="round"
												style={{
													fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
													fontWeight: 600,
													height: '5rem',
													width: '13rem',
													'--padding-left': 0,
													'--background': '#000000',
													'--background-activated': '#000000',
													'--background-hover': '#000000',
													'--color': '#FFFFFF'

												}}
											>
												Download Now
											</IonButton>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '5rem' }}>
										<IonCol style={{ textAlign: 'center' }}>
											<IonRow style={{ height: '35rem' }} >
												<IonCol size={0.25} />
												<IonCol className='monthly' style={{ borderRadius: '4rem', textAlign: 'left' }}>
													<IonRow>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: '3rem',
																color: '#FFFFFF'
															}}>
																Monthly Plan
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '-3rem', }}>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																Designed for travelers or those seeking a reservation for a special occasion.
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '11rem', }}>
														<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																$9/month
															</span>
														</IonCol>
													</IonRow>
													<IonRow>
														<IonCol style={{ padding: '0rem 0rem 0rem 1rem' }}>
															<IonButton
																onClick={() => (toDownload())}
																shape="round"
																style={{
																	marginTop: '1rem',
																	fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																	fontWeight: 600,
																	height: '5rem',
																	width: '13rem',
																	'--padding-left': 0,
																	'--background': '#F6F1EF',
																	'--background-activated': '#F6F1EF',
																	'--background-hover': '#F6F1EF',
																	'--color': '#000000'

																}}
															>
																Download Now
															</IonButton>
														</IonCol>
													</IonRow>
												</IonCol>
												<IonCol size={0.25} />
											</IonRow>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '2rem' }}>
										<IonCol style={{ textAlign: 'center' }}>
											<IonRow style={{ height: '35rem' }} >
												<IonCol size={0.25} />
												<IonCol className='annual' style={{ borderRadius: '4rem', textAlign: 'left' }}>
													<IonRow>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: '3rem',
																color: '#FFFFFF'
															}}>
																Annual Plan
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '-3rem', }}>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																Designed for foodies or those who love to frequent NYC's fine dining scene.
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '11rem', }}>
														<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																$59/year
															</span>
														</IonCol>
													</IonRow>
													<IonRow>
														<IonCol style={{ padding: '0rem 0rem 0rem 1rem' }}>
															<IonButton
																onClick={() => (toDownload())}
																shape="round"
																style={{
																	marginTop: '1rem',
																	fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																	fontWeight: 600,
																	height: '5rem',
																	width: '13rem',
																	'--padding-left': 0,
																	'--background': '#F6F1EF',
																	'--background-activated': '#F6F1EF',
																	'--background-hover': '#F6F1EF',
																	'--color': '#000000'

																}}
															>
																Download Now
															</IonButton>
														</IonCol>
													</IonRow>
												</IonCol>
												<IonCol size={0.25} />
											</IonRow>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '2rem' }}>
										<IonCol style={{ textAlign: 'center' }}>
											<IonRow style={{ height: '35rem' }} >
												<IonCol size={0.25} />
												<IonCol className='business' style={{ borderRadius: '4rem', textAlign: 'left' }}>
													<IonRow>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: '3rem',
																color: '#FFFFFF'
															}}>
																For Business
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '-3rem', }}>
														<IonCol style={{ padding: '2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																Get a discounted rate when you equip your whole team with TableOne. Perfect for client dinners or as a perk.
															</span>
														</IonCol>
													</IonRow>

													<IonRow style={{ marginTop: '9rem', }}>
														<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
															<span style={{
																fontFamily: "'Nunito Sans', sans-serif",
																fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																color: '#FFFFFF'
															}}>
																Minimum 5 team members
															</span>
														</IonCol>
													</IonRow>
													<IonRow>
														<IonCol style={{ padding: '0rem 0rem 0rem 1rem' }}>
															<IonButton
																onClick={() => (toUrl("https://app.formbricks.com/s/cm37i33lv000dgvqxfu4fddan"))}
																shape="round"
																style={{
																	marginTop: '1rem',
																	fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
																	fontWeight: 600,
																	height: '5rem',
																	width: '13rem',
																	'--padding-left': 0,
																	'--background': '#F6F1EF',
																	'--background-activated': '#F6F1EF',
																	'--background-hover': '#F6F1EF',
																	'--color': '#000000'

																}}
															>
																Contact Us
															</IonButton>
														</IonCol>
													</IonRow>
												</IonCol>
												<IonCol size={0.25} />
											</IonRow>
										</IonCol>
									</IonRow>


								</IonCol>
							</IonRow>

							<IonRow
								style={{
									height: '25rem',
									marginTop: '3rem'
								}}
								className='news2'
							>
								<IonCol style={{ textAlign: 'center' }}>
									<IonRow style={{ height: '5rem' }} />

									<IonRow style={{ marginTop: '3rem' }}>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1.4rem',
												color: '#FFFFFF'
											}}>
												“My new plan is to lean on <span style={{ color: "#F2C498" }}>TableOne</span>.”<br/><br/>
											</span>
										</IonCol>
									</IonRow>

									<IonRow style={{ height: '12rem' }} onClick={() => (toUrl("https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying"))} />
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#F6F1EF', height: '30rem', textAlign: 'center' }} className='center-row'>
						<IonCol>
							<IonRow>
								<IonCol>
									<img src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/100plus.png'} style={{ width: '15rem' }} />
								</IonCol>
								<IonCol>
									<IonButton
										onClick={() => (toDownload())}
										shape="round"
										style={{
											fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
											fontWeight: 600,
											height: '5rem',
											width: '13rem',
											'--padding-left': 0,
											'--background': '#000000',
											'--background-activated': '#000000',
											'--background-hover': '#000000',
											'--color': '#FFFFFF'

										}}
									>
										Download Now
									</IonButton>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '5rem', height: '5rem' }}>
								<IonCol>
									<IonRow>
										<IonCol>
											<img src={tableoneBlue} style={{ width: '15rem' }} />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1.4rem',
												color: '#000000'
											}}>
												TableOne Solutions Inc. © 2024
											</span>
										</IonCol>
									</IonRow>
								</IonCol>
							</IonRow>

						</IonCol>
					</IonRow>




				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewNew };
